import styles from './MainLoader.module.scss';
import { ReactComponent as Loader } from '../../../../assets/loaders/MainLoader.svg';

const MainLoader = ({ size = 85 }: { size?: number }) => { // default size 85
  return (
    <div className={styles.loader__wrapper} style={{ width: size, height: size }}>
      <Loader className='importantSvg' style={{ width: size, height: size }} />
    </div>
  );
}

export default MainLoader;
