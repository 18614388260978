import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import TransactionListItem from '../../components/wallet/transaction-list-item';
import { ITransaction } from '../../types/types';
import useFormattedAsset from '../../hooks/useFormattedAsset';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import Typography from '../../components/common/typography';
import Button from '../../components/common/button';

import styles from './Wallet.module.scss';

import List from '../../components/tasks/list';
import Divider from '../../components/common/divider';
import { fetchBalance, fetchTransactions } from '../../store/reducers/walletSlice';
import PageWrapper from '../../components/common/pageWrapper';
import PageContentWrapper from '../../components/common/pageContentWrapper';
import { useTranslation } from 'react-i18next';
import TonWallet from '../../components/wallet/tonWallet';

const Wallet = () => {
  const address = useAppSelector(state => state.account.account.walletAddress);
  const balance = useAppSelector(state => state.wallet.balance);
  const transactions = useAppSelector(state => state.wallet.transactions);
  const username = useAppSelector(state => state.account.account.username);
  const explorerUrl = useAppSelector(state => state.settings.explorerUrl);
  const formattedAsset = useFormattedAsset(null, balance ? balance.stable - balance.outgoing : 0);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const fetchAddressBalance = useCallback(async () => {
    if (!address) {
      return;
    }
    dispatch(fetchBalance(address));
  }, [address, dispatch]);

  const fetchAddressTransactions = useCallback(async () => {
    if (!address) {
      return;
    }
    dispatch(fetchTransactions(address));
  }, [address, dispatch]);

  const formattedUsername = useMemo(() => {
    if (username.length > 15) {
      return `@${username.slice(0, 15)}...`
    }
    return `@${username}`
  }, [username]);

  const setup = useCallback(() => {
    fetchAddressBalance();
    fetchAddressTransactions();
  }, [fetchAddressBalance, fetchAddressTransactions]);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <PageWrapper>
      <PageContentWrapper>
        <>
          <div className={styles.wallet__balance__container}>
            <div className={styles.wallet__balance__header}>
              <Typography variant='subheadline2' color='black'>{t('pages.wallet.sections.balance.title')}</Typography>
              <Typography variant='subheadline2' color='black'>{formattedUsername}</Typography>
            </div>
            <div className={styles.wallet__balance}>
              <Typography variant='title-large' color='black'>{formattedAsset.amount} {formattedAsset.name}</Typography>
            </div>
          </div>

          <div className={styles.wallet__wrapper}>
            <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color='white'>{t('pages.wallet.sections.wallet.title')}</Typography>
            <TonWallet />
          </div>

          <List
            items={transactions?.data || []}
            title={t('pages.wallet.sections.transactions.title')}
            emptyListMessage={t('emptyListText')}
            renderItem={(item: ITransaction, index: number) =>
              <Fragment key={item.hash}>
                <TransactionListItem address={address} transaction={item} />
                {index !== (transactions?.data?.length || 0) - 1 && <Divider />}
              </Fragment>}
          />
          <a style={{ width: '100%', textDecoration: 'none' }} href={`${explorerUrl}/address/${address}`} rel="noreferrer" target="_blank">
            <Button>
              {t('pages.wallet.buttons.walletInfo.title')}
            </Button>
          </a>
        </>
      </PageContentWrapper>
    </PageWrapper>
  );
}

export default Wallet;
