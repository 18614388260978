import { NavLink } from 'react-router-dom';

import { ReactComponent as WalletIcon } from '../../assets/footer/WalletIcon.svg';
import { ReactComponent as TreeIcon } from '../../assets/footer/TreeIcon.svg';
import { ReactComponent as FriendsIcon } from '../../assets/footer/FriendsIcon.svg';
import { ReactComponent as TasksIcon } from '../../assets/footer/TasksIcon.svg';
import { ReactComponent as RankIcon } from '../../assets/footer/RankIcon.svg';

// import { ReactComponent as WalletIcon } from '../../assets/themeIcons/halloween/footer/HalloweenWalletIcon.svg';
// import { ReactComponent as TreeIcon } from '../../assets/themeIcons/halloween/footer/HalloweenTreeIcon.svg';
// import { ReactComponent as FriendsIcon } from '../../assets/themeIcons/halloween/footer/HalloweenFriendsIcon.svg';
// import { ReactComponent as TasksIcon } from '../../assets/themeIcons/halloween/footer/HalloweenTasksIcon.svg';
// import { ReactComponent as RankIcon } from '../../assets/themeIcons/halloween/footer/HalloweenRankIcon.svg';

// import { ReactComponent as WalletIcon } from '../../assets/themeIcons/newYear/footer/WalletIcon.svg';
// import { ReactComponent as TreeIcon } from '../../assets/themeIcons/newYear/footer/TreeIcon.svg';
// import { ReactComponent as FriendsIcon } from '../../assets/themeIcons/newYear/footer/FriendsIcon.svg';
// import { ReactComponent as TasksIcon } from '../../assets/themeIcons/newYear/footer/TasksIcon.svg';
// import { ReactComponent as RankIcon } from '../../assets/themeIcons/newYear/footer/RankIcon.svg';

import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const routes = [
  {
    title: 'footer.items.wallet',
    to: '/wallet',
    icon: WalletIcon,
  },
  {
    title: 'footer.items.tasks',
    to: '/tasks',
    icon: TasksIcon,
  },
  {
    title: 'footer.items.tree',
    to: '/',
    icon: TreeIcon,
  },
  {
    title: 'footer.items.friends',
    to: '/friends',
    icon: FriendsIcon,
  },
  {
    title: 'footer.items.ranking',
    to: '/statistics',
    icon: RankIcon,
  },
];

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <div className={styles.controls}>
        {routes.map((item) =>
          <NavLink key={item.title} to={item.to}>
            {({ isActive }) => (
              <div className={styles.control__wrapper} >
                <item.icon className='importantSvg' stroke={`${isActive ? 'var(--basic--primary)' : 'var(--basic--secondary)'}`} />
                <span style={{ color: `${isActive ? 'var(--basic--primary)' : 'var(--basic--secondary)'}` }}>{t(item.title)}</span>
              </div>
            )}
          </NavLink>
        )}
      </div>
    </div>
  );
}

export default Footer;
