import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TIME_OPTIONS } from '../../../core/wallet';
import { ITransaction } from '../../../types/types';
import Dialog from '../../common/dialog';
import Divider from '../../common/divider';
import Typography from '../../common/typography';

import styles from './DialogTransactionInfo.module.scss';

interface IProps {
  transaction: ITransaction,
  amount: string | number,
  fee: string | number,
  assetName: string,
  handleClose: () => void,
}

const DialogTransactionInfo = ({ transaction, handleClose, amount, assetName }: IProps) => {
  const date = useMemo(() => new Date(transaction.dateTime), [transaction.dateTime]);

  const { t } = useTranslation();

  return (
    <Dialog
      onClose={handleClose}
      title={t('pages.wallet.popups.transactionInfo.title')}
    >
      <div className={styles.transaction_info__wrapper}>
        <div className={styles.transaction_info__item}>
          <Typography variant='subheadline2' color='secondary'>{t('pages.wallet.popups.transactionInfo.fields.status')}</Typography>
          <Typography variant='body1' color='white'>{transaction.status}</Typography>
        </div>
        <Divider />
        <div className={styles.transaction_info__item}>
          <Typography variant='subheadline2' color='secondary'>{t('pages.wallet.popups.transactionInfo.fields.date')}</Typography>
          <Typography variant='body1' color='white'>{date.toLocaleTimeString('en-US', TIME_OPTIONS)}</Typography>
        </div>
        <Divider />
        <div className={styles.transaction_info__item}>
          <Typography variant='subheadline2' color='secondary'>{t('pages.wallet.popups.transactionInfo.fields.amount')}</Typography>
          <Typography variant='body1' color='white'>{`${amount} ${assetName}`}</Typography>
        </div>
        <Divider />
      </div>
    </Dialog>
  );
}

export default DialogTransactionInfo;
